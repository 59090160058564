
body{
    background:#F1F8F8;
    font-family: poppins;
}


.wrapper {
    width: 100%;
}

.main-panel {
    width: calc(100% - 240px);
    float: right;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.center-content {
    padding: 5px 5px 20px 5px;
    margin-top: 100px;
}

.linechartheight{
height:150px !important;
min-height:150px !important;
max-height:150px !important;
}


/* ====================== Loader =================================== */

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgb(34, 34, 34, 85%);
    z-index: 9999;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loadersiz{
    width:90px;
    height:90px;
}

/* .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
} */

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* ====================== Table =================================== */

.table>:not(caption)>*>* {
    border-bottom-width: 0px !important;
}


table.dataTable tbody tr {
    background-color: #ffffff !important;
}

.dataTable th {
    background: #ACE3E5;
}

.buttonsclass {
    flex: 0.5;
    text-align: right;
}

div.dt-center-in-div {
    margin: 0 auto;
    flex: 9;
}

.dt-len {
    flex: 2;
}

div.dt-top-container {
    display: flex !important;
    justify-content: space-between;
}

div.dt-filter-spacer {
    margin: 10px 0;
  }


  .dataTables_wrapper .dataTables_length select {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: #ffffff !important;
    padding: 4px;
    width: 60px !important;
}

.dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
    color: #808080 !important;
    font-size: 13px !important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: #f2f2f2 !important;
    color: #ffffff !important;
    border-radius: 25px;
    min-width: 25px !important;
    height: 25px;
    line-height: 20px !important;
    margin: auto;
    font-size: 13px !important;
    padding:3px !important;
}


.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 2px !important;
    margin-left: 2px !important;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid transparent;
    border-radius: 2px;
}

div#table_paginate img {
    width: 15px;
    height: 15px;
}


table.dataTable tbody td {
    padding: 8px 10px;
    font-size: 13px;
    color: #000000 !important;
}

table.dataTable tbody th{
    padding: 8px 10px;
    font-size: 15px;
    color: #000000 !important;
}

div#table_filter input {
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 5px;
    height: 30px;
    font-size: 13px;
    padding-left: 30px;
}

  .dataTables_filter:before {
    content:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFgSURBVHgBnZLLbYNAEIZ3TQPpIE4HTgexKCAlJKnA9oXHyeGAeAgpUIFJB6QAZJfgEiiBOwIyvzMrOXhBtkcadsTOfvvP7EhxZkEQvEgpt+SLvu8faK1pPZJ/u66biwmTKgjDcEfL+2iilKlt25ux/Rk+URR9AcIKPPInx3Ek1q7rNvx/zZfpL6JyANghmXxpWdZxmBTHMUrdo1zyJZV5uFBEh9849nQQGP6TsoxL3OpyAFogoMRCTFvOoIUWBLkISG41RVH7Kl+nqEaQJMnjFIh6OWdFtRhRdOpL0zQfUyDDMF5ZUTEG8k7BbLbyfV9bP7/aJ+K2bTNdjmTZOV6PZXtoPHqCcugCvOpaTTrtPev6eT7ZKS0rMWI8lGh0xbNU/StNBTTJuBV9OqiG8pAWOAglgJDP6d9eNf9C0TWGw4AANlR2E2gKdjNoDHYXaAgjUGaIO60sy9o0zR/x1570FzUL0vV7+k4JAAAAAElFTkSuQmCC');
    left: 27px;
    position: relative;
    top:5px;

}



.dataTables_wrapper .dataTables_length select {
    border: 0px solid #ffffff !important;
    border-radius: 5px !important;
    background-color: transparent;
    padding: 4px;
    height: 30px;
}

div#table_paginate span {
    position: relative;
    top: -4px;
}

div#table_length {
    font-size: 13px;
    color: #000000;
    font-weight: bold;
}

table.dataTable thead>tr>th.sorting_asc:before, table.dataTable thead>tr>th.sorting_desc:after, table.dataTable thead>tr>td.sorting_asc:before, table.dataTable thead>tr>td.sorting_desc:after {
    opacity: 1 !important;
}

/* ============= Date picker ======= */

.MuiPickersToolbar-toolbar {
    background-color: #046664 !important;
}

.MuiPickersDay-daySelected:hover {
    background-color: #046664 !important;
}


.MuiButton-textPrimary {
    color: #046664 !important;
}

.MuiPickersDay-daySelected {
    background-color: #046664 !important;
}

/* ============= Pages ============ */


.pageheads {
    font-size: 20px;
    font-family: poppins_bold;
    margin-top: 10px;
}



.act_green {
    width: 5px;
    height: 5px;
    background: green;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
}

.act_gry {
    width: 5px;
    height: 5px;
    background: #cccccc;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
}

.actgreencolor{
color:green;
}

.actgreycolor{
    color:#cccccc;
}
.act_gtext{
    display: inline-block;
}



.yearbox {
    height: 120px;
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 25px;
    padding: 20px;
    box-shadow: 1px 2px 6px 0px #cccccc;
}

.year_text {
    text-align: center;
    font-family: 'poppins_bold';
    font-size: 13px;
    color: #000000;
}
.toast-position {
    top: 4rem !important;
  }

.year_num {
    font-size: 40px;
    text-align: center;
    margin-top: 10px;
    font-family: 'poppins';
}

.chartbox_con{
    height:200px !important;
    width:100% !important;
}

.linechartbox_select select {
    height: 25px;
    padding: 1px;
    width: 100px;
    border-radius: 4px;
}

.linechartbox_select {
    width: 100%;
    text-align: right;
    margin-bottom: 6px;
}

.linechartbox {
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    height:350px;
    box-shadow: 1px 2px 6px 0px #cccccc;
}

.pich_center{
    margin: auto;
}

.pichartss{
    height:280px;
}

.dashtable .table {
    box-shadow: 1px 2px 6px 0px #cccccc;
}


.dashtable th {
    background: #046664;
    color: #ffffff;
    font-family: 'poppins_bold';
    border: 0px solid #ccc;
    font-size: 12px;
}

.dashtable td {
    color: #000000;
    font-family: 'poppins';
    font-size: 13px;
    border-bottom: 1px solid #ccc;
}


.dashtable .table {
    border-bottom-left-radius: 0;
    border-top-left-radius: 20px;
}

.dashtable .table {
    border-bottom-right-radius: 0;
    border-top-right-radius: 20px;
}


.dashtable .table thead th:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 20px;
}

.dashtable .table thead th:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 20px;
}


.dashtable table thead th div:not(.tfoot th div) {
    padding: 10px 25px 10px 5px!important;
    background-color: #4f5973!important;
    white-space: nowrap!important;
}


.notetextarea {
    height: 280px;
    width: 100%;
    margin: auto;
    border-radius: 10px;
}

.textareaback {
    background: #162635;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
}

.dashhed {
    font-family: 'Poppins_bold';
    margin-bottom: 15px;
    font-size: 12px;
    height:30px;
}


.btn_logs {
    background: #FD9136 !important;
    text-transform: inherit !important;
    height: 30px !important;
    font-family: poppins !important;
}


.down_act_green{
    font-size: 20px !important;
    text-align: center;
}

.inpsumit{
    display: flex;
}

.inpsumit input{
    margin-right: 5px;
    border-radius:5px;
}


.datepicc {
    margin: 10px;
}


.closemod {
    display: flex;
    justify-content: flex-end;
}

.closecross {
    font-size: 20px;
    color: red;
}

.headtp{
    display: flex;
    justify-content: space-between;
}

/* ============================= */

.datepicinput {
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
}
.datepicinput1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
}


.datepicc input {
    background: #ffffff;
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px 10px;
    height: 25px;
  
    
}


.minutop{
    margin-top: -25px;
}

.MuiDialog-root{
    z-index: 999999 !important;
}

/* ============================= */

.headnotification {
    font-size: 22px;
    margin-top: -4px;
}

.user_man {
    padding: 12px;
}

.usermanage {
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

img.headuserss {
    width: 18px;
    margin-right: 5px;
}

.headerbar {
    width: calc(100% - 240px);
    position: fixed;
    right: 0px;
    z-index: 99;
    padding: 5px 20px 5px 20px;
    height: 60px;
    border-bottom: 1px solid #ecefef;
    background: #F1F8F8;
}

.dropbtn {
    background-color: transparent;
    color: #000000;
    padding: 2px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
}
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: transparent;
  }

/* ============================= */


@media(max-width:991px){
    .dashhed {
        margin-bottom: 5px;
        height: 30px;
        margin-top: 40px;
    }

    .textareaback {
        margin-top: 20px;
    }

}


@media (max-width:1365px){
    .yearbox {
        padding: 5px;
    }
}


@media (max-width:1220px){
    .year_num {
        font-size: 25px;
        text-align: center;
        margin-top: 10px;
        font-family: 'poppins';
    }
    
    .year_text {
        text-align: center;
        font-family: 'poppins_bold';
        font-size: 12px;
        color: #000000;
    }

    .yearbox {
        padding: 2px;
    }
}


@media (min-width: 768px) {
    .container {
        max-width: 100% !important; 
    }
    }
    
    @media (min-width: 200px) {
    .container {
        max-width: 100% !important; 
    }
    }
    
    .container {
        width: 100% !important;
    }