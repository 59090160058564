:root { 
--gray_600:#7f7f7f; 
--white_A700_e5:#ffffffe5; 
--lime_400:#c7e564; 
--gray_400:#bcbcbc; 
--orange_A200:#efa33f; 
--gray_800:#3e3e3e; 
--bluegray_100:#d7d7d7; 
--green_900:#008000; 
--orange_400:#fd9136; 
--gray_101:#f1f8f8; 
--gray_300:#e0dede; 
--gray_100:#f5f5f5; 
--teal_100:#ace3e5; 
--bluegray_900:#162635; 
--black_900:#000000; 
--light_green_500:#96d544; 
--bluegray_400:#888888; 
--cyan_900:#046664; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--black_900_bf:#000000bf; 
--black_900_26:#00000026; 
}