.log_content {
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('images/img_image3.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}


.loginbox {
    width:420px;
    max-width: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 0px;
    background-color: rgba(255,255,255, 0.8);
    padding: 20px 30px;
    border-radius: 10px;
}

.brandname {
    text-align: center;
    font-size: 20px;
    font-family: solway;
    padding: 10px 0px;
    height: 110px;
    width: 100px;
    margin: auto;
}

img.brand_img_log {
    width: 90px;
}


.b_log_text {
    font-size: 20px;
    font-family: 'poppins';
    margin-bottom: 15px;
}

.loginput input {
    border: 0px solid #ccc;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 10px;
    height: 50px;
    padding-left: 45px;
}

.btn_log {
    width: 100%;
    background: #FD9136 !important;
    border-radius: 25px !important;
    text-transform: inherit !important;
    height: 50px  !important;
    font-family: poppins !important;
}
.btn_log1 {
    width: 100%;
    background: #DCDCDC !important;
    border-radius: 25px !important;
    text-transform: inherit !important;
    height: 50px  !important;
    font-family: poppins !important;
}

.logbut {
    width: 60%;
    margin: 15px auto 25px auto;
}

.logbut_log {
    width:100px;
    margin: 0px auto;
}

.log_com_logo{
    width:100%;
}


.pow_brand {
    margin-top: 30px;
}

.log_power {
    text-align: center;
    font-size: 10px;
}

.log_inputs_ic {
    position: relative;
    left: 7px;
    top: -42px;
    color: #b7b7b7;
    width:25px;
}


@media (max-width:768px){
    .loginbox {
        width:310px;
    } 
}